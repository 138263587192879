import React, { useEffect, useState } from 'react';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import './App.scss';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import BookingWidget from './components/BookingWidget/BookingWidget';
import { BookingConfigContextProvider } from './context/bookingConfigContext/bookingConfigContext';
import createAppTheme, { createAppThemeAPI } from './themes/theme';
import defaultBrand from './themes/brand-default.scss';
import { BookingContextProvider } from './context/bookingContext/bookingContext';
import Spinner from './shared/components/Loading/Spinner';
import { LoadingProvider } from './context/loadingContext/loadingContext';
import { NotificationProvider } from './shared/components/Notification/NotificationContext';
import { AgentConfigContextProvider } from './context/agentContext/agentConfigContext';
import { BookingQuoteContextProvider } from './context/quoteContext/quoteContext';
import { BookingDataContextProvider } from './context/bookingDataContext/bookingDataContext';
import '@fontsource/roboto';
import useFetchBookingStyles from './shared/services/useFetchStylesConf';
import { BookingStylesEnum } from './shared/interfaces/bookingStyles.interface';
import { isAgent } from './shared/helpers/auth.helper';
import { AppConfigurationContextProvider } from './context/appConfigurationContext/appConfigurationContext';
import getUrlSearchParam from './shared/helpers/urlSearchParams.helper';
import { abTestingInitialization } from './shared/services/abTesting.service';

export const ThemeContext = React.createContext(null);

const setStyleColors = (colors: any): void => {
  const root = document.documentElement;
  root.style.setProperty('--primary', colors.default.primary);
  root.style.setProperty('--text-primary', colors.default.textPrimary);
  root.style.setProperty('--dark-primary', colors.default.darkPrimary);
  root.style.setProperty('--dark-middle', colors.default.darkMiddle);
  root.style.setProperty('--dark-secondary', colors.default.darkSecondary);
  root.style.setProperty('--light-primary', colors.default.lightPrimary);
  root.style.setProperty('--light-secondary', colors.default.lightSecondary);
  root.style.setProperty('--contrast-primary', colors.default.contrastPrimary);
  root.style.setProperty('--selected-card', colors.default.selectedCard);
  root.style.setProperty(
    '--contrast-secondary',
    colors.default.contrastSecondary,
  );
};
const setStyleColorsApi = (colors: any): void => {
  const root = document.documentElement;
  Object.entries(colors).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value as string);
  });
};

const setFontStyle = (brand: string): void => {
  import(`./themes/fonts/${brand}/fonts.scss`);
};

function App(): JSX.Element {
  LicenseInfo.setLicenseKey(
    '91fe376c8a66f0af99843dea3b0bb39eTz05OTYzNyxFPTE3NjAwODY5NjAwMDAsUz1wcm8sTE09cGVycGV0dWFsLFBWPVEzLTIwMjQsS1Y9Mg==',
  );
  const [theme, setTheme] = useState<Theme>(createAppTheme(defaultBrand, ''));
  const { styleConfig } = useFetchBookingStyles();
  useEffect((): void => {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );
    const brand: string | null = getUrlSearchParam('brand', urlParams);
    localStorage.setItem('language', 'en');
    if (brand && styleConfig && !isAgent()) {
      setFontStyle(brand);
      setTheme(
        createAppThemeAPI(
          styleConfig[BookingStylesEnum.Styles][BookingStylesEnum.Colors],
          brand,
        ),
      );
      setStyleColorsApi(
        styleConfig[BookingStylesEnum.Styles][BookingStylesEnum.Colors],
      );
    } else {
      import('./themes/brand-default.scss').then((colors): void => {
        setTheme(createAppTheme(colors.default, ''));
        setStyleColors(colors);
      });
    }
  }, [styleConfig]);

  useEffect(() => {
    abTestingInitialization();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <BookingConfigContextProvider>
          <BookingQuoteContextProvider>
            <BookingDataContextProvider>
              <NotificationProvider>
                <BookingContextProvider>
                  <AgentConfigContextProvider>
                    <AppConfigurationContextProvider>
                      <CssBaseline enableColorScheme />
                      <Spinner />
                      <div className="App">
                        <BookingWidget />
                      </div>
                    </AppConfigurationContextProvider>
                  </AgentConfigContextProvider>
                </BookingContextProvider>
              </NotificationProvider>
            </BookingDataContextProvider>
          </BookingQuoteContextProvider>
        </BookingConfigContextProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
