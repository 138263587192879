import { formatISO } from 'date-fns';
import { BookingDataEnum } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStep,
  BookingSubStepEnum,
  BookingSubStepOption,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import contextDefaultValues from '../../context/bookingContext/bookingContext.const';
import {
  BookingStepsContextState,
  BookingSubStepsQuotationForm,
} from '../../context/bookingContext/bookingContext.types';
import { getAgeRange } from '../../components/QuotationForm/components/TravellersAgeTiles/travellers-age.const';
import {
  AppBootstrapEnum,
  PossibleValuesInUrl,
} from '../consts/appBootstrap.const';
import {
  insertOptionsIntoObject,
  insertAddonsIntoObject,
} from './insuranceOptions';
import { setCookie } from './cookies.helper';
import getUrlSearchParam from './urlSearchParams.helper';

const getActiveCardConfig = (
  cards: BookingSubStep[],
  cardKey: BookingSubStepValueEnum,
): BookingSubStep =>
  cards.filter(
    (card: BookingSubStep): boolean =>
      card[BookingSubStepEnum.KeyName] === cardKey,
  )[0];

const getBootstrapParamsFromUrl = (
  bookingConfig: BookingConfig,
  bookingSteps: BookingStepsContextState,
): BookingStepsContextState => {
  const searchParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const lowerCaseSearchParams: URLSearchParams = new URLSearchParams();
  Array.from(searchParams.entries()).forEach(([keyT, valueT]): void =>
    lowerCaseSearchParams.append(keyT.toLowerCase(), valueT),
  );
  const extractedParameters: { [key: string]: string } = {};
  PossibleValuesInUrl.forEach((param: BookingDataEnum | AppBootstrapEnum) => {
    const value: string | null = lowerCaseSearchParams.get(param.toLowerCase());
    if (value !== null) {
      extractedParameters[param] = value;
    }
  });

  const defaultValuesBookingSteps: BookingStepsContextState =
    contextDefaultValues.bookingSteps;

  const bookingConfigForm: BookingStep = bookingConfig[
    BookingConfigEnum.BookingSteps
  ].filter(
    (step: BookingStep): boolean =>
      step[BookingStepEnum.KeyName] === BookingStepValueEnum.QuotationForm,
  )[0];

  const bookingConfigProposals: BookingStep = bookingConfig[
    BookingConfigEnum.BookingSteps
  ].filter(
    (step: BookingStep): boolean =>
      step[BookingStepEnum.KeyName] === BookingStepValueEnum.QuotationProposals,
  )[0];

  if (Object.keys(extractedParameters).length > 0) {
    if (extractedParameters[BookingDataEnum.CurrentStep]) {
      defaultValuesBookingSteps[BookingStepValueEnum.CurrentStep] =
        extractedParameters[BookingDataEnum.CurrentStep];
    }

    if (extractedParameters[BookingDataEnum.Partner]) {
      defaultValuesBookingSteps[BookingStepValueEnum.Partner] =
        extractedParameters[BookingDataEnum.Partner];
    }

    if (extractedParameters[BookingDataEnum.QuoteTransactionId]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.QuoteTransactionId
      ] = extractedParameters[BookingDataEnum.QuoteTransactionId];
    }

    if (extractedParameters[BookingDataEnum.CanstarQuoteId]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Metadata
      ] = {
        ...(defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Metadata
        ] || {}),
        [BookingDataEnum.CanstarQuoteId]:
          extractedParameters[BookingDataEnum.CanstarQuoteId],
      };
    }

    if (extractedParameters[AppBootstrapEnum.UtmSource]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Aggregator
      ] = extractedParameters[AppBootstrapEnum.UtmSource];
    }

    if (extractedParameters[BookingDataEnum.AggregatorId]) {
      defaultValuesBookingSteps[BookingStepValueEnum.AggregatorId] =
        extractedParameters[BookingDataEnum.AggregatorId];
    }

    if (extractedParameters[BookingDataEnum.AffiliateId]) {
      defaultValuesBookingSteps[BookingStepValueEnum.AffiliateId] =
        extractedParameters[BookingDataEnum.AffiliateId];
      setCookie(
        'affiliateId',
        extractedParameters[BookingDataEnum.AffiliateId],
        30,
      );
    }

    if (extractedParameters[BookingDataEnum.Catalog]) {
      const activeCard: BookingSubStep = getActiveCardConfig(
        bookingConfigForm[BookingStepEnum.Cards],
        BookingSubStepValueEnum.TripDetails,
      );
      const options: BookingSubStepOption[] = activeCard[
        BookingSubStepEnum.Options
      ] as BookingSubStepOption[];
      const selectedValue = options.filter(
        (option: BookingSubStepOption) =>
          option[BookingSubStepOptionEnum.Value] ===
          extractedParameters[BookingDataEnum.Catalog],
      );
      if (selectedValue.length > 0) {
        // eslint-disable-next-line no-param-reassign
        bookingSteps[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TripDetails
        ] = {
          [BookingSubStepOptionEnum.Value]:
            selectedValue[0][BookingSubStepOptionEnum.Value],
          [BookingSubStepOptionEnum.Label]:
            selectedValue[0][BookingSubStepOptionEnum.Label],
        };
      }
    }

    if (extractedParameters[BookingDataEnum.StartDate]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.DepartureDate
      ] = formatISO(new Date(extractedParameters[BookingDataEnum.StartDate]));
    }

    if (extractedParameters[BookingDataEnum.EndDate]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.ReturnDate
      ] = formatISO(new Date(extractedParameters[BookingDataEnum.EndDate]));
    }

    if (extractedParameters[BookingDataEnum.Destination]) {
      const activeCard: BookingSubStep = getActiveCardConfig(
        bookingConfigForm[BookingStepEnum.Cards],
        BookingSubStepValueEnum.GeographicalZone,
      );
      const options: BookingSubStepOption[] = activeCard[
        BookingSubStepEnum.Options
      ] as BookingSubStepOption[];
      const selectedValue: BookingSubStepOption[] = options.filter(
        (option: BookingSubStepOption): boolean =>
          option[BookingSubStepOptionEnum.Value].toLowerCase() ===
          extractedParameters[BookingDataEnum.Destination].toLowerCase(),
      );
      if (selectedValue.length > 0) {
        defaultValuesBookingSteps[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.GeographicalZone
        ] = {
          [BookingSubStepOptionEnum.Value]:
            selectedValue[0][BookingSubStepOptionEnum.Value],
          [BookingSubStepOptionEnum.Label]:
            selectedValue[0][BookingSubStepOptionEnum.Label],
        };
      }
    }

    if (extractedParameters[BookingDataEnum.MaxTripDuration]) {
      const activeCard: BookingSubStep = getActiveCardConfig(
        bookingConfigForm[BookingStepEnum.Cards],
        BookingSubStepValueEnum.MaxTripDuration,
      );
      const options: BookingSubStepOption[] = activeCard[
        BookingSubStepEnum.Options
      ] as BookingSubStepOption[];
      const selectedValue: BookingSubStepOption[] = options.filter(
        (option: BookingSubStepOption): boolean =>
          option[BookingSubStepOptionEnum.Value].toLowerCase() ===
          extractedParameters[BookingDataEnum.MaxTripDuration].toLowerCase(),
      );
      if (selectedValue.length > 0) {
        defaultValuesBookingSteps[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.MaxTripDuration
        ] = {
          [BookingSubStepOptionEnum.Value]:
            selectedValue[0][BookingSubStepOptionEnum.Value],
          [BookingSubStepOptionEnum.Label]:
            selectedValue[0][BookingSubStepOptionEnum.Label],
        };
      }
    }

    if (extractedParameters[BookingDataEnum.TravellersType]) {
      const activeCard: BookingSubStep = getActiveCardConfig(
        bookingConfigForm[BookingStepEnum.Cards],
        BookingSubStepValueEnum.TravellersType,
      );
      const options: BookingSubStepOption[] = activeCard[
        BookingSubStepEnum.Options
      ] as BookingSubStepOption[];
      const selectedValue = options.filter(
        (option: BookingSubStepOption) =>
          option[BookingSubStepOptionEnum.Value] ===
          extractedParameters[BookingDataEnum.TravellersType],
      );
      if (selectedValue.length > 0) {
        defaultValuesBookingSteps[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TravellersType
        ] = {
          [BookingSubStepOptionEnum.Value]:
            selectedValue[0][BookingSubStepOptionEnum.Value],
          [BookingSubStepOptionEnum.Label]:
            selectedValue[0][BookingSubStepOptionEnum.Value],
        };
      }
    }

    if (extractedParameters[BookingDataEnum.PromoCode]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.PromoCode
      ] = extractedParameters[BookingDataEnum.PromoCode].trim();
      if (extractedParameters[BookingDataEnum.AffiliateId]) {
        setCookie(
          BookingDataEnum.PromoCode,
          extractedParameters[BookingDataEnum.PromoCode],
          30,
        );
      }
    }

    const travellersAge: string[] = [];
    for (let i = 1; i <= 10; i += 1) {
      if (extractedParameters[`companion${i}`]) {
        if (bookingConfig[BookingConfigEnum.Layout] === 'WEB') {
          travellersAge.push(extractedParameters[`companion${i}`]);
        } else {
          const range = getAgeRange(extractedParameters[`companion${i}`]);
          if (range) {
            travellersAge.push(range);
          }
        }
      }
    }
    defaultValuesBookingSteps[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.TravellersAge
    ] = travellersAge;

    if (extractedParameters[BookingDataEnum.Product]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Proposal
      ] = {
        ...defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Proposal
        ],
        code: extractedParameters[BookingDataEnum.Product],
      };
    }

    if (extractedParameters[BookingDataEnum.Addons]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Addons
      ] = insertAddonsIntoObject(extractedParameters[BookingDataEnum.Addons]);
    }

    if (extractedParameters[BookingDataEnum.Options]) {
      defaultValuesBookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.Options
      ] = insertOptionsIntoObject(extractedParameters[BookingDataEnum.Options]);
    }

    if (extractedParameters[BookingDataEnum.ExternalBookingId]) {
      defaultValuesBookingSteps[BookingStepValueEnum.ExtraFields][
        BookingDataEnum.ExternalBookingId
      ] = extractedParameters[BookingDataEnum.ExternalBookingId];
    }

    if (extractedParameters[BookingDataEnum.ExternalBacklink]) {
      defaultValuesBookingSteps[BookingStepValueEnum.ExtraFields][
        BookingDataEnum.ExternalBacklink
      ] = extractedParameters[BookingDataEnum.ExternalBacklink];
    }
  }
  return defaultValuesBookingSteps;
};

const createUrlFromBookingSteps = (
  quotationForm: BookingSubStepsQuotationForm,
  bookingConfig: BookingConfig,
  bookingSteps: BookingStepsContextState,
): string => {
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const brand: string | null = getUrlSearchParam('brand', urlParams);
  let urlString = `brand=${brand}&${BookingDataEnum.CurrentStep}=${BookingStepValueEnum.QuotationProposals}`;
  urlString += `&${BookingDataEnum.Catalog}=${
    quotationForm[BookingSubStepValueEnum.TripDetails][
      BookingSubStepOptionEnum.Value
    ]
  }`;

  urlString += `&${BookingDataEnum.Destination}=${quotationForm[
    BookingSubStepValueEnum.GeographicalZone
  ][BookingSubStepOptionEnum.Value].replace(/&/g, '%26')}`;

  urlString += `&${BookingDataEnum.StartDate}=${
    quotationForm[BookingSubStepValueEnum.DepartureDate]
  }`;

  urlString += `&${BookingDataEnum.EndDate}=${
    quotationForm[BookingSubStepValueEnum.ReturnDate]
  }`;

  urlString += `&${BookingDataEnum.TravellersType}=${
    quotationForm[BookingSubStepValueEnum.TravellersType][
      BookingSubStepOptionEnum.Value
    ]
  }`;

  if (quotationForm[BookingSubStepValueEnum.PromoCode]) {
    urlString += `&${BookingDataEnum.PromoCode}=${
      quotationForm[BookingSubStepValueEnum.PromoCode]
    }`;
  }

  const affiliateId = bookingSteps[BookingStepValueEnum.AffiliateId];
  if (affiliateId) {
    urlString += `&${BookingDataEnum.AffiliateId}=${affiliateId}`;
  }

  const maxTripDuration =
    quotationForm[BookingSubStepValueEnum.MaxTripDuration]?.[
      BookingSubStepOptionEnum.Value
    ];
  if (maxTripDuration) {
    urlString += `&${BookingDataEnum.MaxTripDuration}=${maxTripDuration}`;
  }

  quotationForm[BookingSubStepValueEnum.TravellersAge].forEach(
    (age: string, index: number): void => {
      urlString += `&companion${index + 1}=${age}`;
    },
  );
  return urlString;
};

export { getBootstrapParamsFromUrl, createUrlFromBookingSteps };
