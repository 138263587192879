const SingleTripCatalogsName = 'B2C-AUS-ST';
const CruiseTripCatalogsName = 'B2C-AUS-CRUISE';
const AMTCatalogsName = 'B2C-AUS-AMT';

export const getCatalogLabel = (catalogId: string): string => {
  if (catalogId.includes(SingleTripCatalogsName)) {
    return 'oneTrip';
  }
  if (catalogId.includes(CruiseTripCatalogsName)) {
    return 'cruise';
  }
  if (catalogId.includes(AMTCatalogsName)) {
    return 'amt';
  }
  return '';
};

export default getCatalogLabel;
