import { format, subYears } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import {
  BookingDataResponse,
  BookingManagementEnum,
  Companion,
  CompanionEnum,
} from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import {
  ApiDateFormat,
  DEFAULT_ZERO_AGE_DATE_OFFSET_DAYS,
} from '../consts/app.const';
import {
  FormQuotationInformationTraveller,
  FormQuotationInformationTravellerEnum,
} from '../../components/QuotationInformationForm/quoation-information.interface';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';

export const getDateOfBirth = (age: string): string => {
  if (age === '0') {
    return format(
      dayjs().subtract(DEFAULT_ZERO_AGE_DATE_OFFSET_DAYS, 'day').toDate(),
      ApiDateFormat,
    );
  }
  const currentDate = new Date();
  const utcCurrentDate = new Date(
    Date.UTC(
      currentDate.getUTCFullYear() - +age,
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
    ),
  );
  return format(utcCurrentDate, ApiDateFormat);
};

export const getTravellerAgeFromRange = (ageRange: string): string => {
  let age: string;
  if (ageRange === '<18') {
    age = '17';
  } else if (ageRange === '100+') {
    age = '101';
  } else if (!ageRange.includes('-')) {
    age = ageRange;
  } else {
    // eslint-disable-next-line prefer-destructuring
    age = ageRange.split('-')[1];
  }
  return age;
};

export const getCompanionId = (
  companions: Companion[],
  index: number,
  bookingDataResponse: BookingDataResponse | null,
): number | undefined => {
  if (
    bookingDataResponse &&
    bookingDataResponse[BookingManagementEnum.Companions] &&
    bookingDataResponse[BookingManagementEnum.Companions][index]
  ) {
    return +bookingDataResponse[BookingManagementEnum.Companions][index][
      CompanionEnum.Id
    ]!;
  }
  return undefined;
};

export const getCompanionsAge = (
  ageRanges: string[],
  bookingDataResponse: BookingDataResponse | null,
): Companion[] => {
  const travellersAge: Companion[] = [];
  ageRanges.forEach((ageRange: string, index: number): void => {
    const age = getTravellerAgeFromRange(ageRange);
    travellersAge.push({
      [CompanionEnum.DateOfBirth]: getDateOfBirth(age),
      [CompanionEnum.Age]: +age,
      [CompanionEnum.IsCustomer]: index === 0,
    });
    const companionId = getCompanionId(
      travellersAge,
      index,
      bookingDataResponse,
    );
    if (companionId) {
      travellersAge[index][CompanionEnum.Id] = companionId;
    }
  });
  return travellersAge;
};

export const getTravellersAge = (
  ageRanges: string[],
): { birthDate: string }[] => {
  const travellersAge: { birthDate: string; age?: string }[] = [];
  ageRanges.forEach((ageRange: string): void => {
    const age = getTravellerAgeFromRange(ageRange);
    travellersAge.push({ birthDate: getDateOfBirth(age) });
  });
  return travellersAge;
};

export const getBirthdateFromAge = (
  age: string | undefined,
  birthdayFormat: string,
): string => {
  if (age === '0') {
    return format(
      dayjs().subtract(DEFAULT_ZERO_AGE_DATE_OFFSET_DAYS, 'day').toDate(),
      birthdayFormat,
    );
  }
  const currentDate = new Date();
  const utcCurrentDate = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
    ),
  );
  const birthdate = subYears(utcCurrentDate, +age!);
  return format(birthdate, birthdayFormat);
};

export const DATES_SUPPORTED_FORMATS_PS_DAYJS = ['DD/MM/YYYY', 'YYYY-MM-DD'];

export const getTravellerAgeFromDate = (
  birthDate: Dayjs | string | undefined,
  birthDateFormat = 'YYYY-MM-DD',
): number => {
  if (!birthDate) {
    return 0;
  }

  const today = dayjs();
  let birthDay: Dayjs;

  if (dayjs.isDayjs(birthDate)) {
    birthDay = birthDate;
  } else if (typeof birthDate === 'string') {
    const parsedDate = DATES_SUPPORTED_FORMATS_PS_DAYJS.map((psformat) =>
      dayjs(birthDate, psformat),
    ).find((date) => date.isValid());

    if (parsedDate && parsedDate.isValid()) {
      birthDay = parsedDate;
    } else {
      birthDay = dayjs(birthDate);
      if (!birthDay.isValid()) {
        return 0;
      }
    }
  } else {
    return 0;
  }

  let age = today.year() - birthDay.year();
  if (today.isBefore(birthDay.add(age, 'year'))) {
    age -= 1;
  }

  return age;
};

export const getAgeForTraveller = (
  travellerAge: string | Dayjs | undefined,
): number =>
  dayjs.isDayjs(travellerAge)
    ? getTravellerAgeFromDate(travellerAge)
    : +travellerAge!;

export const getBirthdateForTraveller = (
  travellerAge: string | Dayjs | undefined,
): string =>
  dayjs.isDayjs(travellerAge)
    ? format((travellerAge as Dayjs).toDate(), ApiDateFormat)
    : getBirthdateFromAge(travellerAge, ApiDateFormat);

export const getTravellerAge = (
  traveller: FormQuotationInformationTraveller,
  bookingConfigData: BookingConfig,
): string | number | undefined => {
  const travellerAge = traveller[FormQuotationInformationTravellerEnum.Age];
  const travellerBirthDate =
    traveller[FormQuotationInformationTravellerEnum.BirthDate];
  if (!travellerAge && travellerBirthDate) {
    return getTravellerAgeFromDate(travellerBirthDate as unknown as Dayjs);
  }
  return typeof travellerAge === 'string' && travellerAge.includes('/')
    ? dayjs().diff(
        dayjs(
          travellerAge,
          bookingConfigData[BookingConfigEnum.BirthdayFormat],
        ),
        'year',
      )
    : traveller.age;
};

export default getTravellersAge;
