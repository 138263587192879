export type ConsoleMethod = 'log' | 'info' | 'warn' | 'error' | 'debug';

export const sendLogToGlassbox = (
  level: ConsoleMethod,
  message: string,
  additionalData = {},
): void => {
  try {
    if (
      (window as any)._detector &&
      typeof (window as any)._detector.triggerCustomEventMap === 'function'
    ) {
      (window as any)._detector.triggerCustomEventMap(
        level,
        {
          message,
          timestamp: new Date().toISOString(),
          ...additionalData,
        },
        [],
      );
    }
  } catch (error) {
    console.error('Error sending log to Glassbox:', error);
  }
};

export const interceptConsoleLogs = (): void => {
  if ((window as any)._detector && !(window as any)._consoleIntercepted) {
    (['log', 'info', 'error'] as ConsoleMethod[]).forEach((level) => {
      const original = window.console[level].bind(console);

      window.console[level] = ((
        ...args: Parameters<(typeof console)[typeof level]>
      ) => {
        try {
          const message = args.map((arg) => JSON.stringify(arg)).join(' ');
          sendLogToGlassbox(level, message);
          original(...args);
        } catch (error) {
          console.error('Error in console interception:', error);
        }
      }) as (typeof console)[typeof level];
    });

    (window as any)._consoleIntercepted = true;
  } else {
    console.warn(
      'Glassbox _detector is not available yet or logs are already being intercepted.',
    );
  }
};

export const interceptErrors = (): void => {
  window.onerror = (message, source, lineno, colno, error) => {
    const logMessage = {
      type: 'error',
      message: message?.toString() || 'Unknown JS error',
      source: source || 'unknown',
      line: lineno || 0,
      column: colno || 0,
      stack: error?.stack || 'No stack trace',
    };

    sendLogToGlassbox('error', JSON.stringify(logMessage));
  };

  window.onunhandledrejection = (event) => {
    const logMessage = {
      type: 'unhandledrejection',
      reason: event.reason?.toString() || 'Unknown rejection',
      stack: event.reason?.stack || 'No stack trace',
    };

    sendLogToGlassbox('error', JSON.stringify(logMessage));
  };
};
