import dayjs from 'dayjs';
import { format } from 'date-fns';
import { ApiDateFormat, ApiOutputDateFormat } from '../consts/app.const';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import {
  prepareAddons,
  getPartnerCode,
  prepareOptions,
} from './insuranceOptions';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import {
  InsuranceData,
  InsuranceDataEnum,
  InsuranceEnum,
  InsurancePayload,
} from '../interfaces/insurance.interface';
import getTravellersTypeValue from './getTravellersTypeValue';

const getInsurancePayload = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  { agentEmail }: { agentEmail?: string } = {},
): InsurancePayload => {
  const addonsValues =
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.Addons
    ];
  const optionsValues =
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.Options
    ];
  const creditAmount =
    bookingSteps[BookingStepValueEnum.QuotationProposals]?.[
      BookingSubStepValueEnum.CreditAmount
    ];
  const agentsDiscount =
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.AgentsDiscount
    ];
  const discretionaryDiscount = agentsDiscount?.discountStatus ===
    'APPROVED' && {
    [InsuranceDataEnum.DiscretionaryDiscountReason]:
      agentsDiscount?.selectedDiscountReason,
    ...(agentsDiscount.isPercentageDiscount
      ? {
          [InsuranceDataEnum.DiscretionaryDiscountPercentage]:
            +agentsDiscount.discountAmount / 100,
        }
      : {
          [InsuranceDataEnum.DiscretionaryDiscountAmount]:
            +agentsDiscount.discountAmount,
        }),
  };

  const insuranceData: InsuranceData = {
    ...(agentEmail ? { [InsuranceDataEnum.AgentSales]: agentEmail } : {}),
    [InsuranceDataEnum.Brand]: bookingConfigData[BookingConfigEnum.Brand],
    [InsuranceDataEnum.IsFamilyOrCouple]: getTravellersTypeValue(
      bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TravellersType
      ]?.value,
    ),
    [InsuranceDataEnum.GeographicalZone]:
      bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.GeographicalZone
      ][BookingSubStepOptionEnum.Value],
    [InsuranceDataEnum.InsuranceOptions]: prepareOptions(optionsValues),
    [InsuranceDataEnum.InsuranceAddons]: prepareAddons(addonsValues),
    [InsuranceDataEnum.State]:
      bookingSteps.quotationInformationForm.informationPersonal?.state,
    [InsuranceDataEnum.PartnerCode]: getPartnerCode(bookingSteps),
    [InsuranceDataEnum.CreditAmount]: creditAmount
      ? `${creditAmount}`
      : undefined,
    ...discretionaryDiscount,
  };
  const maxTripDuration =
    bookingSteps[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.MaxTripDuration
    ]?.value;
  if (maxTripDuration) {
    insuranceData[InsuranceDataEnum.MaxTripDuration] = +maxTripDuration;
  }
  return {
    [InsuranceEnum.BookingDate]: format(new Date(), ApiDateFormat),
    [InsuranceEnum.StartDate]: `${dayjs(
      bookingSteps[BookingStepValueEnum.QuotationForm].departureDate,
    ).format(ApiOutputDateFormat)}`,
    [InsuranceEnum.EndDate]: `${dayjs(
      bookingSteps[BookingStepValueEnum.QuotationForm].returnDate,
    ).format(ApiOutputDateFormat)}`,
    [InsuranceEnum.InsuranceData]: insuranceData,
  };
};

export default getInsurancePayload;
