import dayjs, { Dayjs } from 'dayjs';
import { format, isValid, parse, parseISO } from 'date-fns';

const DATES_SUPPORTED_FORMATS_PS = ['dd/MM/yyyy', 'yyyy-MM-dd', 'yyyy/MM/dd'];
export const DATES_SUPPORTED_FORMATS_PS_DAYJS = ['DD/MM/YYYY', 'YYYY-MM-DD'];

const parseStringToDate = (dateString: string): Date => {
  const parsedDate =
    DATES_SUPPORTED_FORMATS_PS.map((dateFormat) =>
      parse(dateString, dateFormat, new Date()),
    ).find((date) => isValid(date)) || new Date(dateString);
  if (!isValid(parsedDate)) {
    throw new Error(`Can't parse date: ${dateString}`);
  }
  return parsedDate;
};

export const addTimeToDate = (date: dayjs.Dayjs): dayjs.Dayjs => {
  const now = dayjs();
  return dayjs(date).hour(now.hour()).minute(now.minute()).second(now.second());
};

export const parseDateTimeStringToDate = (date: string): string => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'MM/dd/yyyy');
};

export const customParseFunction = (dateString: string): Dayjs =>
  dayjs(dateString, DATES_SUPPORTED_FORMATS_PS_DAYJS, true);

export const customParseFunctionRefresh = (dateString: string): Dayjs => {
  const parts = dateString.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  return dayjs().set('year', year).set('month', month).set('date', day);
};

export default parseStringToDate;
