import { BookingFieldApi } from './BookingConfig/fieldsBookingConf';

export enum BookingConfigEnum {
  Brand = 'brand',
  Locale = 'locale',
  DateFormat = 'dateFormat',
  DateTimeFormat = 'dateTimeFormat',
  BirthdayFormat = 'birthdayFormat',
  B2cUrl = 'b2cUrl',
  PdsUrl = 'pdsUrl',
  Country = 'country',
  Currency = 'currency',
  DataCenter = 'dataCenter',
  PaymentProvider = 'paymentProvider',
  ClientId = 'client_id',
  ClientSecret = 'client_secret',
  Catalogs = 'catalogs',
  BookingSteps = 'bookingSteps',
  BookingFieldsApi = 'bookingFieldsApi',
  Validation = 'validation',
  AgentScripts = 'agentScripts',
  Consents = 'consents',
  AbandonReasons = 'abandonReasons',
  BrandProductReview = 'brandProductReview',
  Layout = 'layout',
  PaymentConfig = 'paymentConfig',
  CreditPaymentEnabled = 'creditPaymentEnabled',
  GlassboxUrl = 'glassboxUrl',
}

export interface BookingConfig {
  [BookingConfigEnum.Brand]: string;
  [BookingConfigEnum.Locale]: string;
  [BookingConfigEnum.DateFormat]: string;
  [BookingConfigEnum.DateTimeFormat]: string;
  [BookingConfigEnum.BirthdayFormat]: string;
  [BookingConfigEnum.B2cUrl]: string;
  [BookingConfigEnum.PdsUrl]: string;
  [BookingConfigEnum.Country]: Country;
  [BookingConfigEnum.Currency]: Currency;
  [BookingConfigEnum.DataCenter]: DataCenter;
  [BookingConfigEnum.PaymentProvider]: string;
  [BookingConfigEnum.ClientId]: string;
  [BookingConfigEnum.ClientSecret]: string;
  [BookingConfigEnum.Catalogs]: BookingCatalog[];
  [BookingConfigEnum.BookingSteps]: BookingStep[];
  [BookingConfigEnum.BookingFieldsApi]: BookingFieldApi[];
  [BookingConfigEnum.Validation]: BookingValidation;
  [BookingConfigEnum.AbandonReasons]: AbandonReason[];
  [BookingConfigEnum.Layout]: string;
  [BookingConfigEnum.Layout]: string;
  [BookingConfigEnum.PaymentConfig]: PaymentConfig;
  [BookingConfigEnum.BrandProductReview]?: string;
  [BookingConfigEnum.CreditPaymentEnabled]?: boolean;
  [BookingConfigEnum.GlassboxUrl]?: string;
}

export enum BannerEnum {
  Id = 'id',
  Content = 'content',
}

export interface Banner {
  [BannerEnum.Id]: string;
  [BannerEnum.Content]: string;
}

export enum AgentScriptsEnum {
  Text = 'text',
  Texts = 'texts',
}

export interface AgentScripts {
  [AgentScriptsEnum.Text]?: string;
  [AgentScriptsEnum.Texts]?: { [key: string]: string };
}

export enum BookingStepEnum {
  Id = 'id',
  Label = 'label',
  Locale = 'locale',
  KeyName = 'keyName',
  StepNameApi = 'stepNameApi',
  Component = 'component',
  SendQuoteEmailEnabled = 'sendQuoteEmailEnabled',
  HeaderText = 'headerText',
  SendQuoteOnEnterStep = 'sendQuoteOnEnterStep',
  FooterText = 'footerText',
  Cards = 'cards',
  Widgets = 'widgets',
  Banner = 'banner',
  AdditionalData = 'additionalData',
  AgentScripts = 'agentScripts',
}

export interface BookingStep {
  [BookingStepEnum.Id]: number;
  [BookingStepEnum.Label]: string;
  [BookingStepEnum.Locale]: string;
  [BookingStepEnum.KeyName]: string;
  [BookingStepEnum.StepNameApi]: string;
  [BookingStepEnum.Component]: string;
  [BookingStepEnum.SendQuoteEmailEnabled]: boolean;
  [BookingStepEnum.SendQuoteOnEnterStep]: boolean;
  [BookingStepEnum.HeaderText]: string;
  [BookingStepEnum.FooterText]: string;
  [BookingStepEnum.Cards]: BookingSubStep[];
  [BookingStepEnum.Widgets]: BookingStepWidget;
  [BookingStepEnum.Banner]?: Banner;
  [BookingStepEnum.AdditionalData]?: GenericObject;
  [BookingStepEnum.AgentScripts]?: AgentScripts;
}

export enum BookingStepWidgetEnum {
  ProductReview = 'productReview',
  CanstarComparison = 'canstarComparison',
  UsefulLinks = 'usefulLinks',
}

export interface BookingStepWidget {
  [BookingStepWidgetEnum.ProductReview]?: ProductReview;
  [BookingStepWidgetEnum.CanstarComparison]?: CanstarComparison;
  [BookingStepWidgetEnum.UsefulLinks]?: UsefulLink[];
}

export enum ProductReviewEnum {
  Brand = 'brand',
  Url = 'url',
  Title = 'title',
}

export interface ProductReview {
  [ProductReviewEnum.Brand]: string;
  [ProductReviewEnum.Url]: string;
  [ProductReviewEnum.Title]: string;
}

export type CanstarComparison = string[];

export enum UsefulLinkEnum {
  Title = 'title',
  Link = 'link',
}

export interface UsefulLink {
  [UsefulLinkEnum.Title]: string;
  [UsefulLinkEnum.Link]: string;
}

export enum UsefulLinksEnum {
  Title = 'title',
  Links = 'links',
}

export interface UsefulLinks {
  [UsefulLinksEnum.Title]: string;
  [UsefulLinksEnum.Links]: UsefulLink[];
}

export enum BookingSubStepEnum {
  Id = 'id',
  Label = 'label',
  KeyName = 'keyName',
  Component = 'component',
  Description = 'description',
  Locale = 'locale',
  Options = 'options',
  AgentScripts = 'agentScripts',
}

export interface BookingSubStep {
  [BookingSubStepEnum.Id]?: number;
  [BookingSubStepEnum.KeyName]: BookingSubStepValueEnum;
  [BookingSubStepEnum.Label]: string;
  [BookingSubStepEnum.Component]: string;
  [BookingSubStepEnum.Description]?: string;
  [BookingSubStepEnum.Locale]?: string;
  [BookingSubStepEnum.Options]: BookingSubStepOption[] | Declaration[];
  [BookingSubStepEnum.AgentScripts]?: AgentScripts;
}

export enum BookingSubStepOptionEnum {
  Label = 'label',
  Description = 'description',
  Value = 'value',
  Image = 'image',
  Icon = 'icon',
  Locale = 'locale',
  Version = 'version',
  isDefault = 'isDefault',
  AgentExlusive = 'agentExlusive',
  MappingValue = 'mappingValue',
  CatalogSpecific = 'catalogSpecific',
}

export interface BookingCatalog {
  code: string;
  isAnnual: boolean;
  id?: number;
}

export interface BookingSubStepOption {
  [BookingSubStepOptionEnum.Label]: string;
  [BookingSubStepOptionEnum.Description]?: string;
  [BookingSubStepOptionEnum.Value]: string;
  [BookingSubStepOptionEnum.Image]?: string;
  [BookingSubStepOptionEnum.Icon]?: string;
  [BookingSubStepOptionEnum.Version]?: number;
  [BookingSubStepOptionEnum.Locale]?: string;
  [BookingSubStepOptionEnum.isDefault]?: string;
  [BookingSubStepOptionEnum.AgentExlusive]?: boolean;
  [BookingSubStepOptionEnum.MappingValue]?: string;
  [BookingSubStepOptionEnum.CatalogSpecific]?: CatalogSpecific[];
}

export enum BookingStepValueEnum {
  CurrentStep = 'currentStep',
  Partner = 'partner',
  AggregatorId = 'aggregatorId',
  AffiliateId = 'affiliateId',
  QuotationForm = 'quotationForm',
  QuotationProposals = 'quotationProposals',
  QuotationInformation = 'quotationInformationForm',
  QuotationRecapDeclaration = 'quotationRecapDeclarations',
  QuotationPreliminaryDeclarations = 'quotationPreliminaryDeclarations',
  ExtraFields = 'extraFields',
}

export enum BookingSubStepValueEnum {
  TripDetails = 'tripDetails',
  GeographicalZone = 'geographicalZone',
  DepartureDate = 'departureDate',
  ReturnDate = 'returnDate',
  TravellersType = 'travellersType',
  TravellersAge = 'travellersAge',
  PromoCode = 'promoCode',
  TotalPrice = 'totalPrice',
  Proposal = 'proposal',
  Tables = 'tables',
  Addons = 'addons',
  Options = 'options',
  InformationData = 'informationData',
  InformationPersonal = 'informationPersonal',
  InformationTravellers = 'informationTravellers',
  InformationMedical = 'informationMedical',
  MedicalDisclaimerConsent = 'medicalDisclaimerConsent',
  MedicalScreening = 'medicalScreening',
  PreflightConsents = 'preflightConsents',
  PreflightChecks = 'preflightChecks',
  Consents = 'consents',
  MaxTripDuration = 'maxTripDuration',
  CreditAmount = 'creditAmount',
  MedicalPriceBreakdown = 'medicalPriceBreakdown',
  IsPolicyHolderTravelling = 'isPolicyHolderTravelling',
  AgentsDiscount = 'agentsDiscount',
  PromoRate = 'promoRate',
  FamCoupleRate = 'famCoupleRate',
  QuoteTransactionId = 'quoteTransactionId',
  Aggregator = 'aggregator',
  Metadata = 'metadata',
}

export interface BookingValidation {
  age: AgeValidation[];
  ageOfMajorityValidation: AgeOfMajorityValidation[];
  tripDurationValidation: TripDurationValidation[];
  startDateValidation: StartDateValidation[];
  numberOfTravellersValidation: NumberOfTravellersValidation[];
  phoneValidation: PhoneValidation[];
  postalCodeValidation: PostalCodeValidation[];
  medicalScoreValidation: MedicalScoreValidation[];
}

export interface AgeValidation {
  catalog: string;
  firstTravellersAgeValidation: { min: string; max: string };
  otherTravellersAgeValidation: { min: string; max: string };
}

export interface AgeOfMajorityValidation {
  catalog: string;
  age: string;
}

export interface TripDurationDestinationLimit {
  appliedFromAge?: string;
  daysLimit: string;
  destination: string;
}

export interface TripDurationValidation {
  catalog: string;
  min: string;
  ageLimit: string;
  maxIfYoungerThanAgeLimit: string;
  maxIfOlderThanAgeLimit: string;
  destinationLimits: TripDurationDestinationLimit[];
}

export interface StartDateValidation {
  catalog: string;
  ageLimit: string;
  maxIfYoungerThanAgeLimit: string;
  maxIfOlderThanAgeLimit: string;
}

export interface NumberOfTravellersValidation {
  catalog: string;
  max: string;
}

export interface PhoneValidation {
  catalog: string;
  length: string;
}

export interface PostalCodeValidation {
  catalog: string;
  length: string;
}

export interface MedicalScoreValidation {
  catalog: string;
  max: string;
}

export interface AgentConfig {
  scripts: AgentConfigScript[];
}

export interface AgentConfigScript {
  step: string;
  substep?: string;
  text?: string;
  texts?: { [key: string]: string };
}

export interface Country {
  countries: string;
  locale?: string;
  id?: number;
}

export enum CurrencyEnum {
  Id = 'id',
  Currency = 'currency',
  Locale = 'locale',
  FormatOptions = 'formatOptions',
}

export interface Currency {
  [CurrencyEnum.Id]?: number;
  [CurrencyEnum.Currency]: string;
  [CurrencyEnum.Locale]: string;
  [CurrencyEnum.FormatOptions]: Intl.NumberFormatOptions;
}

export interface DataCenter {
  code: string;
  cm360Endpoint: string;
  psPartnerEndpoint: string;
  psPlatform: string;
  cm360Key: string;
  psClient: string;
  widgetUrl: string;
  id?: number;
}

export interface StatesConfig {
  isEnabled: boolean;
  states: GeoState[];
}

export interface GeoState {
  code: string;
  label: string;
}

export interface Declaration {
  id?: number;
  usageType: string;
  description: string;
  agentExlusive: boolean;
  version: number;
  channel: string;
  group: boolean;
  isOptional: boolean;
  label?: string;
  isDefault?: boolean;
  catalogSpecific?: CatalogSpecific[];
}

export interface AbandonReason {
  abandonReasonId: string;
  label: string;
}

export enum PaymentConfigEnum {
  PCIPAL = 'PCIPAL',
}

export enum PaymentConfigPCIPALEnum {
  Region = 'region',
  TenantId = 'tenantId',
  Environment = 'environment',
}

export interface PaymentConfigPCIPAL {
  [PaymentConfigPCIPALEnum.Region]: string;
  [PaymentConfigPCIPALEnum.TenantId]: string;
  [PaymentConfigPCIPALEnum.Environment]: string;
}

export interface PaymentConfig {
  [PaymentConfigEnum.PCIPAL]: PaymentConfigPCIPAL;
}

type GenericObject<T = any> = {
  [key: string]: T;
};

export enum AdditionalDataEnum {
  StatesConfig = 'statesConfig',
  Text = 'text',
  PolicyWordingDocs = 'policyWordingDocs',
  PolicyHolderIsTraveller = 'policyHolderIsTraveller',
  TravelerAgeType = 'travelerAgeType',
}

export interface CatalogSpecific {
  code: string;
  isAnnual: boolean;
  id: number;
}

export interface AddonConfig {
  value: 'string';
  label: 'string';
  description: 'string';
}
