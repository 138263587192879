import { format } from 'date-fns';
import {
  BookingQuoteSubscriber,
  BookingQuoteSubscriberEnum,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuote.interface';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import parseStringToDate from './date.helper';
import { FormQuotationInformationEnum } from '../../components/QuotationInformationForm/quoation-information.interface';
import { ApiDateFormat } from '../consts/app.const';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';

const getCivilityValue = (
  bookingStepsContextState: BookingStepsContextState,
): string =>
  bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
    BookingSubStepValueEnum.InformationPersonal
  ]?.[FormQuotationInformationEnum.Title] ||
  bookingStepsContextState[
    BookingStepValueEnum.QuotationPreliminaryDeclarations
  ]?.[BookingSubStepValueEnum.InformationPersonal]?.[
    FormQuotationInformationEnum.Title
  ] ||
  '';

const getSubscriberObject = (
  bookingConfig: BookingConfig,
  bookingStepsContextState: BookingStepsContextState,
): BookingQuoteSubscriber => ({
  [BookingQuoteSubscriberEnum.PreferredLanguage]:
    localStorage.getItem('language') || '',
  [BookingQuoteSubscriberEnum.CountryCode]:
    bookingConfig[BookingConfigEnum.Country].countries.toUpperCase(),
  [BookingQuoteSubscriberEnum.Civility]: getCivilityValue(
    bookingStepsContextState,
  ),
  [BookingQuoteSubscriberEnum.Birthdate]: bookingStepsContextState[
    BookingStepValueEnum.QuotationInformation
  ][BookingSubStepValueEnum.InformationPersonal]?.[
    FormQuotationInformationEnum.Birthdate
  ]
    ? format(
        parseStringToDate(
          bookingStepsContextState[BookingStepValueEnum.QuotationInformation][
            BookingSubStepValueEnum.InformationPersonal
          ][FormQuotationInformationEnum.Birthdate],
        ),
        ApiDateFormat,
      )
    : '',
  [BookingQuoteSubscriberEnum.FirstName]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Firstname] ||
    bookingStepsContextState[
      BookingStepValueEnum.QuotationPreliminaryDeclarations
    ]?.[BookingSubStepValueEnum.InformationPersonal]?.[
      FormQuotationInformationEnum.Firstname
    ] ||
    '',
  [BookingQuoteSubscriberEnum.LastName]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Lastname] ||
    bookingStepsContextState[
      BookingStepValueEnum.QuotationPreliminaryDeclarations
    ]?.[BookingSubStepValueEnum.InformationPersonal]?.[
      FormQuotationInformationEnum.Lastname
    ] ||
    '',
  [BookingQuoteSubscriberEnum.Email]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Email] || '',
  [BookingQuoteSubscriberEnum.MobilePhone]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Phone] || '',
  [BookingQuoteSubscriberEnum.Phone]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Phone] || '',
  [BookingQuoteSubscriberEnum.Address1]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Address] || '',
  [BookingQuoteSubscriberEnum.Address2]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Address2] || '',
  [BookingQuoteSubscriberEnum.PostalCode]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.Postcode] || '',
  [BookingQuoteSubscriberEnum.City]:
    bookingStepsContextState[BookingStepValueEnum.QuotationInformation]?.[
      BookingSubStepValueEnum.InformationPersonal
    ]?.[FormQuotationInformationEnum.City] || '',
});

export { getSubscriberObject, getCivilityValue };
