import { differenceInYears, format } from 'date-fns';
import dayjs from 'dayjs';
import { BookingStepsContextState } from '../../../context/bookingContext/bookingContext.types';
import {
  AmountEnum,
  BookingDataEnum,
  BookingDataResponse,
  BookingManagement,
  BookingManagementEnum,
  Companion,
  CompanionEnum,
  Consent,
  ConsentUsage,
  CustomerEnum,
  ProxyEnum,
} from './bookingManagment.interface';
import {
  AdditionalDataEnum,
  BookingConfig,
  BookingConfigEnum,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
  Declaration,
} from '../bookingSteps.interface';
import {
  BookingQuoteResponse,
  BookingQuoteResponseEnum,
  BookingQuoteResponseQuote,
  BookingQuoteResponseQuoteEnum,
  BookingQuoteResponseQuoteProductEnum,
  BookingQuoteResponseQuoteProductPriceEnum,
} from '../BookingQuoteManagment/bookingQuoteResponse.interface';
import {
  FormQuotationInformationEnum,
  FormQuotationInformationTravellerList,
} from '../../QuotationInformationForm/quoation-information.interface';
import {
  getAgeForTraveller,
  getBirthdateForTraveller,
  getBirthdateFromAge,
  getCompanionsAge,
  getDateOfBirth,
  getTravellerAgeFromRange,
} from '../../../shared/helpers/getTravellersAge';
import { ApiDateFormat } from '../../../shared/consts/app.const';
import parseStringToDate from '../../../shared/helpers/date.helper';
import { ProductEnum } from '../../QuotationProposals/useFetchProposalsConf';
import getBookingStepIndex from '../../../shared/helpers/getBookingStepIndex.helper';

const getConsentsStructure = (
  consents: Declaration[],
  response: Consent[] | undefined,
  multipleConsents?: boolean,
): Consent[] => {
  if (multipleConsents) {
    const usages: ConsentUsage[] = [];
    consents.forEach((item: Declaration): void => {
      usages.push({
        type: item.usageType,
        version: item.version,
        isAgentExclusive: item.agentExlusive,
      });
    });
    response!.push({ usages });
  } else {
    consents.forEach((item: Declaration): void => {
      let alreadyExists = false;
      if (!response || response.length === 0) {
        alreadyExists = false;
      } else {
        response.forEach((responseItem: Consent): void => {
          responseItem.usages.forEach((usage: ConsentUsage): void => {
            if (usage.type === item.usageType) {
              alreadyExists = true;
            }
          });
        });
      }

      if (!alreadyExists) {
        response!.push({
          usages: [
            {
              type: item.usageType,
              version: item.version,
              isAgentExclusive: item.agentExlusive,
            },
          ],
        });
      }
    });
  }
  return <Consent[]>response;
};

const getCompanionId = (
  companions: Companion[],
  index: number,
  bookingDataResponse: BookingDataResponse | null,
): Companion[] => {
  if (
    bookingDataResponse &&
    bookingDataResponse[BookingManagementEnum.Companions] &&
    bookingDataResponse[BookingManagementEnum.Companions][index]
  ) {
    // eslint-disable-next-line no-param-reassign
    companions[index][CompanionEnum.Id] =
      bookingDataResponse[BookingManagementEnum.Companions][index][
        CompanionEnum.Id
      ]!;
  }
  return companions;
};

const getCompanionsArray = (
  bookingStepsContext: BookingStepsContextState,
  bookingConfig: BookingConfig,
  bookingDataResponse: BookingDataResponse | null,
): Companion[] => {
  const quotationInformation =
    bookingStepsContext[BookingStepValueEnum.QuotationInformation];
  const quotationForm = bookingStepsContext[BookingStepValueEnum.QuotationForm];
  const travellersList =
    quotationInformation[BookingSubStepValueEnum.InformationTravellers];
  const informationPersonal =
    quotationInformation[BookingSubStepValueEnum.InformationPersonal];
  const companionsAge = getCompanionsAge(
    quotationForm[BookingSubStepValueEnum.TravellersAge],
    bookingDataResponse,
  );
  if (!informationPersonal || travellersList === null) {
    return companionsAge;
  }
  let companions: Companion[] = [];
  const ageString = 'travellerAge';
  const firstNameString = 'travellerFirstName';
  const lastNameString = 'travellerLastName';
  let i = 1;
  const dateOfBirth = parseStringToDate(
    informationPersonal[FormQuotationInformationEnum.Birthdate] ||
      (travellersList.travellerAge0 as any).format('DD/MM/YYYY'),
  );
  const isPolicyHolderTravellingValue =
    bookingStepsContext[BookingStepValueEnum.QuotationInformation][
      BookingSubStepValueEnum.IsPolicyHolderTravelling
    ];
  const QuotationInforamtionStepIndex = getBookingStepIndex(
    bookingConfig,
    BookingStepValueEnum.QuotationInformation,
  );

  const firstCompanion =
    isPolicyHolderTravellingValue ||
    (bookingConfig[BookingConfigEnum.BookingSteps][
      QuotationInforamtionStepIndex
    ][BookingStepEnum.AdditionalData]?.[
      AdditionalDataEnum.PolicyHolderIsTraveller
    ] ??
      true)
      ? {
          [CompanionEnum.Age]: differenceInYears(new Date(), dateOfBirth),
          [CompanionEnum.DateOfBirth]: format(dateOfBirth, ApiDateFormat),
          [CompanionEnum.IsCustomer]: true,
          [CompanionEnum.FirstName]:
            informationPersonal[FormQuotationInformationEnum.Firstname],
          [CompanionEnum.LastName]:
            informationPersonal[FormQuotationInformationEnum.Lastname],
        }
      : {
          [CompanionEnum.Age]: +(
            travellersList[
              `${ageString}${0}` as keyof FormQuotationInformationTravellerList
            ] || 0
          ),
          [CompanionEnum.DateOfBirth]: getBirthdateForTraveller(
            travellersList[
              `${ageString}${0}` as keyof FormQuotationInformationTravellerList
            ],
          ),
          [CompanionEnum.IsCustomer]: false,
          [CompanionEnum.FirstName]:
            travellersList[
              `${firstNameString}${0}` as keyof FormQuotationInformationTravellerList
            ],
          [CompanionEnum.LastName]:
            travellersList[
              `${lastNameString}${0}` as keyof FormQuotationInformationTravellerList
            ],
        };
  companions.push(firstCompanion);
  companions = getCompanionId(companions, 0, bookingDataResponse);
  while (i < 11) {
    const keyAge: string = `${ageString}${i}`.toString();
    const keyFirstName: string = `${firstNameString}${i}`.toString();
    const keyLastName: string = `${lastNameString}${i}`.toString();
    if (
      travellersList[keyAge as keyof FormQuotationInformationTravellerList] &&
      companionsAge[i]
    ) {
      companions.push({
        [CompanionEnum.IsCustomer]: false,
        [CompanionEnum.Age]: getAgeForTraveller(
          travellersList[keyAge as keyof FormQuotationInformationTravellerList],
        ),
        [CompanionEnum.DateOfBirth]: getBirthdateForTraveller(
          travellersList[keyAge as keyof FormQuotationInformationTravellerList],
        ),
        [CompanionEnum.FirstName]: travellersList[
          keyFirstName as keyof FormQuotationInformationTravellerList
        ] as string,
        [CompanionEnum.LastName]: travellersList[
          keyLastName as keyof FormQuotationInformationTravellerList
        ] as string,
      });
      companions = getCompanionId(companions, i, bookingDataResponse);
    } else if (companionsAge[i]) {
      companions.push(companionsAge[i]);
    }
    // eslint-disable-next-line no-plusplus
    i++;
  }
  return companions;
};

const getFormattedDate = (date: any): string =>
  date.$d ? date.format('YYYY-MM-DD') : date;

const parseDataToBookingManagement = (
  bookingStepsContextState: BookingStepsContextState,
  bookingConfig: BookingConfig,
  quoteResponse: BookingQuoteResponse,
  bookingDataResponse: BookingDataResponse | null,
): BookingManagement => {
  const quoteData: BookingQuoteResponseQuote =
    quoteResponse[BookingQuoteResponseEnum.Quotes][0];
  const maxTripDuration =
    bookingStepsContextState[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.MaxTripDuration
    ]?.[BookingSubStepOptionEnum.Value];
  const bookingManagement: BookingManagement = {
    [BookingManagementEnum.BookingData]: {
      [BookingDataEnum.CurrentStep]:
        bookingStepsContextState[BookingStepValueEnum.CurrentStep],
      [BookingDataEnum.Catalog]:
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TripDetails
        ][BookingSubStepOptionEnum.Value],
      [BookingDataEnum.Region]: '',
      [BookingDataEnum.Destination]:
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.GeographicalZone
        ][BookingSubStepOptionEnum.Value],
      ...(maxTripDuration
        ? { [BookingDataEnum.MaxTripDuration]: maxTripDuration }
        : {}),
      [BookingDataEnum.CountryCode]: `${
        bookingConfig[BookingConfigEnum.DataCenter].psPlatform
      }`,
      [BookingDataEnum.Product]:
        bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Proposal
        ][ProductEnum.Code],
      [BookingDataEnum.Aggregators]: {
        [BookingDataEnum.QuoteTransactionId]:
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.QuoteTransactionId
          ],
        [BookingDataEnum.Aggregator]:
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.Aggregator
          ],
        [BookingDataEnum.Metadata]:
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.Metadata
          ],
      },
      [BookingDataEnum.StartDate]: getFormattedDate(
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.DepartureDate
        ] as any,
      ),
      [BookingDataEnum.EndDate]: getFormattedDate(
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.ReturnDate
        ] as any,
      ),
      [BookingDataEnum.TravellersType]:
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TravellersType
        ].value,
      [BookingDataEnum.ProductName]:
        bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Proposal
        ].description,
      [BookingDataEnum.Addons]:
        bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Addons
        ],
      [BookingDataEnum.Options]:
        bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Options
        ],
      [BookingDataEnum.PromoCode]:
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.PromoCode
        ],
      [BookingDataEnum.IsWinterSport]:
        bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Addons
        ].WINTERSPORTS,
      [BookingDataEnum.CatalogVersion]:
        bookingDataResponse?.[BookingManagementEnum.BookingData][
          BookingDataEnum.CatalogVersion
        ] ?? '',
    },
  };
  if (quoteData) {
    bookingManagement[BookingManagementEnum.BookingData][
      BookingDataEnum.Proxy
    ] = {
      [ProxyEnum.QuoteId]: quoteData[BookingQuoteResponseQuoteEnum.QuoteId],
      [ProxyEnum.PolicyId]: '',
      [ProxyEnum.Amount]: {
        [AmountEnum.Amount]:
          +quoteData[BookingQuoteResponseQuoteEnum.Product][
            BookingQuoteResponseQuoteProductEnum.Price
          ][BookingQuoteResponseQuoteProductPriceEnum.GrossPremium].toFixed(2),
        [AmountEnum.Currency]:
          quoteData[BookingQuoteResponseQuoteEnum.Product][
            BookingQuoteResponseQuoteProductEnum.Price
          ][BookingQuoteResponseQuoteProductPriceEnum.Currency],
      },
      [ProxyEnum.BrandCode]: bookingConfig[BookingConfigEnum.Brand],
      // @TODO real data
      [ProxyEnum.BusinessUnitCode]: '',
    };
  }

  if (bookingStepsContextState[BookingStepValueEnum.QuotationInformation]) {
    const personalData =
      bookingStepsContextState[BookingStepValueEnum.QuotationInformation][
        BookingSubStepValueEnum.InformationPersonal
      ];
    if (personalData && personalData.birthdate) {
      bookingManagement[BookingManagementEnum.BookingData][
        BookingDataEnum.Region
      ] = personalData[FormQuotationInformationEnum.State];
      bookingManagement[BookingManagementEnum.Customer] = {
        [CustomerEnum.Title]: personalData[FormQuotationInformationEnum.Title],
        [CustomerEnum.EmailAddress]:
          personalData[FormQuotationInformationEnum.Email],
        [CustomerEnum.FirstName]:
          personalData[FormQuotationInformationEnum.Firstname],
        [CustomerEnum.LastName]:
          personalData[FormQuotationInformationEnum.Lastname],
        [CustomerEnum.DateOfBirth]: format(
          parseStringToDate(
            personalData[FormQuotationInformationEnum.Birthdate],
          ),
          ApiDateFormat,
        ),
        [CustomerEnum.AddressLine1]:
          personalData[FormQuotationInformationEnum.Address],
        [CustomerEnum.AddressLine2]:
          personalData[FormQuotationInformationEnum.Address2],
        [CustomerEnum.AddressLine3]:
          personalData[FormQuotationInformationEnum.City],
        [CustomerEnum.AddressLine4]:
          personalData[FormQuotationInformationEnum.State],
        [CustomerEnum.PostalCode]:
          personalData[FormQuotationInformationEnum.Postcode],
        [CustomerEnum.CountryCode]: `${
          bookingConfig[BookingConfigEnum.Country].countries
        }`,
        [CustomerEnum.PhoneNumber1]:
          personalData[FormQuotationInformationEnum.Phone],
        [CustomerEnum.PhoneNumber2]: '',
      };
    } else {
      const traveller0Age =
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TravellersAge
        ][0];
      const preliminaryPersonalData =
        bookingStepsContextState[
          BookingStepValueEnum.QuotationPreliminaryDeclarations
        ]?.[BookingSubStepValueEnum.InformationPersonal];
      bookingManagement[BookingManagementEnum.Customer] = {
        [CustomerEnum.DateOfBirth]: format(
          new Date(getDateOfBirth(getTravellerAgeFromRange(traveller0Age))),
          ApiDateFormat,
        ),
        [CustomerEnum.Title]:
          preliminaryPersonalData?.[FormQuotationInformationEnum.Title],
        [CustomerEnum.FirstName]:
          preliminaryPersonalData?.[FormQuotationInformationEnum.Firstname],
        [CustomerEnum.LastName]:
          preliminaryPersonalData?.[FormQuotationInformationEnum.Lastname],
      };
    }

    bookingManagement[BookingManagementEnum.Companions] = getCompanionsArray(
      bookingStepsContextState,
      bookingConfig,
      bookingDataResponse,
    );
    if (quoteResponse[BookingQuoteResponseEnum.AccessToken]) {
      bookingManagement[BookingManagementEnum.Customer][
        CustomerEnum.AccessToken
      ] = quoteResponse[BookingQuoteResponseEnum.AccessToken];
    }

    bookingManagement[BookingManagementEnum.Consents] = [];
    if (
      bookingStepsContextState[
        BookingStepValueEnum.QuotationPreliminaryDeclarations
      ] &&
      bookingStepsContextState[
        BookingStepValueEnum.QuotationPreliminaryDeclarations
      ][BookingSubStepValueEnum.PreflightConsents]
    ) {
      bookingManagement[BookingManagementEnum.Consents] = getConsentsStructure(
        bookingStepsContextState[
          BookingStepValueEnum.QuotationPreliminaryDeclarations
        ][BookingSubStepValueEnum.PreflightConsents],
        bookingDataResponse &&
          bookingDataResponse[BookingManagementEnum.Consents]
          ? bookingDataResponse[BookingManagementEnum.Consents]
          : [],
        false,
      );
    }
    if (
      bookingStepsContextState[BookingStepValueEnum.QuotationRecapDeclaration]
    ) {
      bookingManagement[BookingManagementEnum.Consents] = getConsentsStructure(
        bookingStepsContextState[
          BookingStepValueEnum.QuotationRecapDeclaration
        ],
        bookingDataResponse &&
          bookingDataResponse[BookingManagementEnum.Consents]
          ? bookingDataResponse[BookingManagementEnum.Consents]
          : [],
        true,
      );
    }
  }
  return bookingManagement;
};

export default parseDataToBookingManagement;
