import { format } from 'date-fns';
import {
  BookingStepsContextState,
  BookingSubStepsQuotationProposals,
} from '../../../context/bookingContext/bookingContext.types';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../bookingSteps.interface';
import {
  BookingQuote,
  BookingQuoteBeneficiaries,
  BookingQuoteEnum,
  BookingQuoteInsuranceDataEnum,
  BookingQuoteInsuranceEnum,
  BookingQuoteProductEnum,
  BookingQuoteSubscriptionEnum,
} from './bookingQuote.interface';
import { ApiDateFormat } from '../../../shared/consts/app.const';
import {
  getPartnerCode,
  prepareAddons,
  prepareOptions,
} from '../../../shared/helpers/insuranceOptions';
import { ProductEnum } from '../../QuotationProposals/useFetchProposalsConf';
import { getSubscriberObject } from '../../../shared/helpers/subscriber.helper';
import getBeneficiariesArray from '../../../shared/helpers/beneficiaries.helper';
import getTravellersTypeValue from '../../../shared/helpers/getTravellersTypeValue';
import { BookingDataResponse } from '../BookingManagment/bookingManagment.interface';
import formatApiDate from '../../../shared/helpers/formatDate';

const getPrice = (
  bookingProposal: BookingSubStepsQuotationProposals,
): string => {
  if (bookingProposal[BookingSubStepValueEnum.TotalPrice]) {
    return bookingProposal[BookingSubStepValueEnum.TotalPrice].toString();
  }
  return bookingProposal[BookingSubStepValueEnum.Proposal][
    ProductEnum.Price
  ].grossPremium.toString();
};

const parseDataToQuote = (
  bookingStepsContextState: BookingStepsContextState,
  bookingConfig: BookingConfig,
  {
    email,
    quoteId,
    agentEmail,
  }: { email?: string | boolean; quoteId?: string; agentEmail?: string } = {},
  bookingDataResponse: BookingDataResponse | null = null,
): BookingQuote => {
  const activeStep = bookingConfig[BookingConfigEnum.BookingSteps].filter(
    (step: BookingStep) =>
      step[BookingStepEnum.KeyName] ===
      bookingStepsContextState[BookingStepValueEnum.CurrentStep],
  )[0];

  const beneficiaries: BookingQuoteBeneficiaries[] = getBeneficiariesArray(
    bookingStepsContextState,
    bookingConfig,
    bookingDataResponse,
  );

  const maxTripDuration =
    bookingStepsContextState[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.MaxTripDuration
    ]?.[BookingSubStepOptionEnum.Value];

  const agentsDiscount =
    bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.AgentsDiscount
    ];

  const discretionaryDiscount = agentsDiscount?.discountStatus ===
    'APPROVED' && {
    [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountReason]:
      agentsDiscount?.selectedDiscountReason,
    ...(agentsDiscount.isPercentageDiscount
      ? {
          [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountPercentage]:
            +agentsDiscount.discountAmount / 100,
        }
      : {
          [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountAmount]:
            +agentsDiscount.discountAmount,
        }),
  };

  const quote: BookingQuote = {
    ...(!email
      ? {
          [BookingQuoteEnum.QuotationStep]:
            activeStep[BookingStepEnum.StepNameApi],
          [BookingQuoteEnum.Product]: {
            [BookingQuoteProductEnum.ProductGroup]: [
              {
                [BookingQuoteProductEnum.Code]:
                  bookingStepsContextState[
                    BookingStepValueEnum.QuotationProposals
                  ][BookingSubStepValueEnum.Proposal][ProductEnum.Code],
              },
            ],
          },
        }
      : {}),
    [BookingQuoteEnum.Insurance]: {
      [BookingQuoteInsuranceEnum.BookingDate]: format(
        new Date(),
        ApiDateFormat,
      ),
      [BookingQuoteInsuranceEnum.StartDate]: formatApiDate(
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.DepartureDate
        ],
      ),
      [BookingQuoteInsuranceEnum.EndDate]: formatApiDate(
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.ReturnDate
        ],
      ),
      [BookingQuoteInsuranceEnum.InsuranceData]: {
        ...(agentEmail
          ? { [BookingQuoteInsuranceDataEnum.AgentSales]: agentEmail }
          : {}),
        [BookingQuoteInsuranceDataEnum.Brand]:
          bookingConfig[BookingConfigEnum.Brand],
        [BookingQuoteInsuranceDataEnum.IsFamilyOrCouple]:
          getTravellersTypeValue(
            bookingStepsContextState[BookingStepValueEnum.QuotationForm][
              BookingSubStepValueEnum.TravellersType
            ]?.value,
          ),
        [BookingQuoteInsuranceDataEnum.GeographicalZone]:
          bookingStepsContextState[BookingStepValueEnum.QuotationForm][
            BookingSubStepValueEnum.GeographicalZone
          ][BookingSubStepOptionEnum.Value],
        ...(maxTripDuration
          ? { [BookingQuoteInsuranceDataEnum.MaxTripDuration]: maxTripDuration }
          : {}),
        [BookingQuoteInsuranceDataEnum.InsuranceOptions]: prepareOptions(
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.Options
          ],
        ),
        [BookingQuoteInsuranceDataEnum.InsuranceAddons]: prepareAddons(
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.Addons
          ],
        ),
        ...(email
          ? {
              [BookingQuoteInsuranceDataEnum.QuoteId]: quoteId,
            }
          : {}),
        [BookingQuoteInsuranceDataEnum.PartnerCode]: getPartnerCode(
          bookingStepsContextState,
        ),
        ...discretionaryDiscount,
      },
    },
    ...(!email
      ? {
          [BookingQuoteEnum.Subscription]: {
            [BookingQuoteSubscriptionEnum.Price]: getPrice(
              bookingStepsContextState[BookingStepValueEnum.QuotationProposals],
            ),
          },
        }
      : {}),
    [BookingQuoteEnum.Subscriber]: getSubscriberObject(
      bookingConfig,
      bookingStepsContextState,
    ),
    [BookingQuoteEnum.Beneficiaries]: beneficiaries,
    ...(email
      ? {
          [BookingQuoteEnum.Email]: email,
        }
      : {}),
    [BookingQuoteEnum.Promotion]: {
      [BookingQuoteInsuranceEnum.PromotionCode]:
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.PromoCode
        ],
    },
  };
  return quote;
};

export default parseDataToQuote;
