// Configuration for A/B testing
const AB_TEST_CONFIG = [
  { version: '1', probability: 0.5 },
  { version: '2', probability: 0.5 },
];
const STORAGE_KEY = 'ab_testing_version'; // Key to store version in localStorage

// Utility function to get a random version based on probabilities
function getRandomABTestingVersion(): string {
  const random = Math.random();
  let cumulativeProbability = 0;

  const selectedVersion = AB_TEST_CONFIG.find((config) => {
    cumulativeProbability += config.probability;
    return random < cumulativeProbability;
  });

  // Fallback in case probabilities are misconfigured
  return selectedVersion ? selectedVersion.version : AB_TEST_CONFIG[0].version;
}

export const getABTestingVersionFromLocalStorage = (): string | null =>
  localStorage.getItem(STORAGE_KEY);

export const abTestingInitialization = (): void => {
  let abTestingVersion = getABTestingVersionFromLocalStorage();
  if (!abTestingVersion) {
    // Assign a new random version if not already stored
    abTestingVersion = getRandomABTestingVersion();
    localStorage.setItem(STORAGE_KEY, abTestingVersion);
  }
  window.parent.postMessage(
    {
      type: 'AB_TESTING_VERSION',
      abTestingVersion,
    },
    '*',
  );
};
