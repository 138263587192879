import axios from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { sendLogToGlassbox } from '../helpers/glassbox.helper';

const useApi = (bookingConfigData: BookingConfig): any => {
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const apiCM360Key: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Key;

  const api = axios.create({
    baseURL: apiCM360Url,
  });

  api.interceptors.request.use(
    async (config) => {
      const modifiedConfig = { ...config };
      const token = localStorage.getItem('token');
      const anonymousToken = localStorage.getItem('anonymousToken') || null;

      if (!modifiedConfig.headers['X-Api-Key']) {
        modifiedConfig.headers['X-Api-Key'] = apiCM360Key;
      }

      modifiedConfig.headers['Content-Type'] = 'application/json';

      if (!modifiedConfig.headers.Authorization && (token || anonymousToken)) {
        modifiedConfig.headers.Authorization = token
          ? `Bearer ${token}`
          : `Bearer ${anonymousToken}`;
      }

      sendLogToGlassbox('info', 'API Request Sent', {
        method: modifiedConfig.method,
        url: modifiedConfig.url,
        headers: JSON.stringify(modifiedConfig.headers),
      });

      return modifiedConfig;
    },
    (error) => {
      sendLogToGlassbox('error', 'API Request Error', {
        error: JSON.stringify(error),
      });
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => {
      sendLogToGlassbox('info', 'API Response Received', {
        method: response.config.method,
        url: response.config.url,
        status: response.status,
        statusText: response.statusText,
        headers: JSON.stringify(response.headers),
      });

      return response;
    },
    (error) => {
      if (error.response) {
        sendLogToGlassbox('error', 'API Response Error', {
          method: error.config.method,
          url: error.config.url,
          status: error.response.status,
          statusText: error.response.statusText,
        });
      } else {
        sendLogToGlassbox('error', 'Network Error', {
          error: JSON.stringify(error),
        });
      }
      return Promise.reject(error);
    },
  );

  return api;
};

export default useApi;
